import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/suspect/Documents/coding/dbs-dresden/src/componets/mdxTemplate.js";
import { AspectImage, Flex, Box, Image } from "@theme-ui/components";
import P1 from "../images/Alte20Leipziger.gif";
import P2 from "../images/Gothaer.gif";
import P3 from "../images/RV.gif";
import P4 from "../images/Axa.gif";
import P5 from "../images/NC3BCrnberger.gif";
import P6 from "../images/Inter.gif";
import P7 from "../images/Barmenia.gif";
import P8 from "../images/Alte20Oldenburger.gif";
import P9 from "../images/Universa.gif";
import P10 from "../images/DKV.gif";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <title>Geschäftsfelder</title>
    <h1>{`Investment`}</h1>
    <p>{`Bei kaum einem anderen Thema scheiden sich die Geister heute so sehr. Viele folgten nach den Börsencrashs von 2002 und 2008 der Devise: Nie wieder Aktien. Gleichzeitig aber hört man immer wieder, das Aktien auf lange Sicht, den größeren Anlageerfolg bieten. Was soll man also tun und wem Glauben schenken? Nun, sicher nicht den vollmundigen Werbeversprechen der Fondsindustrie. Denn jeder, der sein Geld in Fonds investiert, sollte wissen, dass er damit am Produktivkapital teilhat. Das heisst, dass er auf ein nachhaltiges Gewinnwachstum der entsprechenden Branche oder Volkswirtschaft setzt. Doch die Erfahrung zeigt, dass Bäume nun einmal nicht in den Himmel wachsen.Der durchschnittliche Gewinn, den das Produktivkapital erzielt, leigt bei 6 bis 7%. Mehr, so kann man daher sagen, "ist einfach nicht drin". Oder wenn doch, dann ist dies eine riskante Spekulation, die wenig mit der Wertschöpfung der entsprechenden Branche zu tun hat, sondern damit, dass viele auf denselben Zug aufspringen und den Preis in die Höhe treiben. Augenmaß und Zurückhaltung sind deshalb die Grunddtugenden einer vernünftigen Anlageentscheidung. Jeder Konjunkturzyklus hat seine Zeit. Bäume wachsen nciht in den Himmel. Spekulationsblasen platzen oft unverhofft und mit ihnen hochgesteckte Konsumziele.`}</p>
    <h1>{`Private Rente`}</h1>
    <p>{`Durch den gewaltigen demographische Wandel, vor dem Deutschland in den kommenden Jahrzehnten steht, nimmt das Thema privater Altersvorsorge in jeder vernünftigen Vorsorgekonzeption einen immer größeren Stellenwert ein. Allein um sich eine gesetzliche Altersrente auf dem Niveau der sogenannten Grundsicherung zu erarbeiten, sind mittlerweile schon 27 Beitragsjahre vonnöten. Selbst bei 45 Beitragsjahren und einem durchschnittlichen Einkommen wird die spätere Rente kaum 1200 Euro betragen. Dabei ist der Einfluss der Inflation noch gar nicht berücksichtigt worden. Diese Zahlen allein belegen die unbedingte Notwendigkeit, privat etwas für die Rente zu tun. Um auf diesem Feld mit Lösungen für die verschiedensten Lebenssituationen aufwarten zu können, haben wir uns zu einer Zusammenarbeit mit den meisten namhaften deutschen und europäischen Versicherern entschlossen. Die Liste unserer Partner reicht dabei von A wie Aachener Münchener bis Z wie Zürich und umfasst so namhafte Partner wie Allianz, Alte Leipziger, Axa, Generali, Gerling, Gothaer, HDI-Gerling, Nürnberger oder Victoria. Nicht zu vergessen natürlich auch die Versicherungen aus dem angelsächsichen Raum wie Canada Life, Clerical Medical oder Standard Life.`}</p>
    <Flex sx={{
      justifyContent: "space-around"
    }} mdxType="Flex">
    {[P1, P2, P3, P4].map(src => <Box p="2" mdxType="Box">
            <Image sx={{
          maxWidth: "100px"
        }} src={src} mdxType="Image" />
        </Box>)}
    </Flex>
    <h1>{`Krankenversicherung`}</h1>
    <p>{`Wer schon einmal beim Arzt keinen Termin bekommen hat, der weiss, was es heisst, gesetzlich versichert zu sein. Wer dagegen auch bei akuten Erkrankungen nicht erst lange im Wartezimmer herumsitzen musste, sondern sofort ins Behandlungszimmer gerufen wurde, der hat seinen Status als Privatpatient schätzen gelernt. Doch obwohl gesetzlich stets gleich gesetzlich versichert ist, ist privat versichert nicht gleich privat versichert. Nicht nur decken die unterschiedlichen Tarife einen unterschiedlichen Behandlungsumfang ab. Manch ein Tarif umfasst Besonderheiten wie mehrjährige Weltgeltung, kostenfreien Impfschutz auch bei berufsbedingten Impfungen. Ganz zu schweigen von den unterschiedlichen Selbstbehaltsstufen und Verhaltensboni. Daraus resultieren dann weit über 1000 verschiedene Tarife für jede einzelne Person. Und wir bieten sie selbstverständlich nicht nur alle an, sondern führen natürlich auch durch den manchmal fast undurchdringelichen Tarifdschungel.`}</p>
    <Flex sx={{
      justifyContent: "space-around"
    }} mdxType="Flex">
    {[P1, P5, P6, P7].map(src => <Box p="2" mdxType="Box">
            <Image sx={{
          maxWidth: "100px"
        }} src={src} mdxType="Image" />
        </Box>)}
    </Flex>
    <Flex sx={{
      justifyContent: "space-around"
    }} mdxType="Flex">
    {[P8, P9, P10].map(src => <Box p="2" mdxType="Box">
            <Image sx={{
          maxWidth: "100px"
        }} src={src} mdxType="Image" />
        </Box>)}
    </Flex>
    <h1>{`Risikoabsicherung`}</h1>
    <p>{`Eine Haftpflichtversicherung sollte jeder besitzen. Aber benötigt man auch eine Versicherung, die den privaten Schlüsselverlust versichert. Muss man sich gegen alle Eventualitäten versichern. Nun, als Merksatz könnte man sich notieren, dass man einen Risikoschutz dort benötigt, wo man den wirtschaftlichen Schaden nicht aus eigener Kraft tragen könnte. Deshalb benötigt jeder eine Haftpflichtversicherung, weil der Schaden, den man einer anderen Person zufügt, weit größer sein kann, als das eigene Vermögen, mit dem man haftet. Gut zu wissen ist aber auch, dass die Preisspanne bei Risikoversicherungen mitunter bei mehr als 400% zwischen der teuersten und der günstigsten Versicherung liegen kann. Deshalb lohnt sich nicht nur ein Vergleich. Es lohnt sich vor allem, in gewissen Abständen die eigenen Versicherungen auf den Prüfstand zu stellen. Selten genug nämlich informiert einen die eigene Versicherung über eventuelle Tarifneuerungen. Alle Risikoversicherungen zusammen genommen ergibt sich oft ein Einsparpotential von mehreren hundert Euro pro Jahr. Um auf diesem Sektor adäquat reagieren zu können, stehen uns die Angebotsprogramme von über 90 Gesellschaften zur Verfügung. Genug also für eine adäquate Risikoabsicherung.`}</p>
    <h1>{`Finanzierung`}</h1>
    <p>{`Des Deutschen liebstes Kind sind immer noch die eigenen vier Wände, egal, ob sie selbst drin wohnen oder sie an jemanden vermietet haben. Knapp 50 Prozent der Deutschen besitzen eine Immobilie und rund 29 Prozent sehen in den eigenen vier Wänden die wichtigste Form der Altersvorsorge. Denn mag der Wert einer Immobilie auch Schwankungen unterworfen sein. Eins steht fest: Für die eigene Wohnung oder das eigene Häuschen muss man im Alter keine Miete mehr zahlen. Auf der anderen Seite muss man aber auch wissen, dass fast die Hälfte aller Immobilienfinanzierungen bedingt durch Ehescheidungen oder Arbeitslosigkeit abgebrochen werden. Deshalb ist es gerade beim Thema Finanzierung wichtig, dass alle Parameter stimmen, also nicht nur Lage und Ausstattungsgrad, sondern vor allem Flexibiltität in der Rückzahlung des Darlehens und niedrige Finanzierungskosten. Anlass für uns, mit starken, vor allem aber mit günstigen Finanzierungspartnern zu kooperieren. Schwerpunktmäßig arbeiten wir deshalb mit der Deutschen Kreditbank DKB, der ING-Diba sowie der DSL Bank, aber auch noch mit 50 weiteren regionalen und überregionalen Finanzierungspartnern zusammen. Damit sind wir tagesaktuell in der Lage, die jeweils günstigsten Finanzierungsbedingungen am Markt anzubieten.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      